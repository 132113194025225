import React from "react";
import { createUseStyles } from "react-jss";

import { identifyApp } from "../lib/identify-app";

const useStyles = createUseStyles({
  centered: {
    backgroundColor: "white",
    boxSizing: "border-box",
    color: "#34495E",
    fontFamily: "sans-serif",
    fontSize: "14px",
    height: "100vh",
    left: 0,
    letterSpacing: "normal",
    lineHeight: 1.4,
    overflowX: "hidden",
    position: "absolute",
    top: 0,
    width: "100vw",
    zIndex: 1000,
  },
  flexWrap: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "flex-start",
  },
  inner: {
    boxSizing: "content-box",
    margin: "auto 0",
    position: "relative",
  },
  modal: {
    borderRadius: "10px",
    boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)",
    padding: "50px 55px",
    position: "relative",
    width: "350px",
  },
  description: {
    borderTop: "1px solid #e6e8ed",
    color: "#666",
    lineHeight: "22px",
    marginTop: "35px",
    paddingTop: "25px",
    textAlign: "center",
  },
  copy: {
    color: "#aaa",
    lineHeight: "22px",
    marginTop: "20px",
    textAlign: "center",
  },
  link: {
    color: "#5296bf",
    fontWeight: "bold",
    textDecoration: "none",
  },
});

interface Props {
  children: React.ReactNode;
}

const Centered: React.FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();
  const { name, contact } = identifyApp();
  return (
    <div className={classes.centered}>
      <div className={classes.flexWrap}>
        <div className={classes.inner}>
          <div className={classes.modal}>
            {children}
            <div className={classes.description}>
              If you are not a member but would like to know more
              <br />
              about {name} and its features, please&nbsp;
              <a
                className={classes.link}
                href={contact}
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
              .
            </div>
          </div>
          <div className={classes.copy}>
            If you would like to know more about McKinsey & Company’s
            <br />
            privacy practices, please read this&nbsp;
            <a
              className={classes.link}
              href="https://www.mckinsey.com/solutions-privacy-statement"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Statement
            </a>
            .
            <br />
            <br />
            We strive to provide individuals with disabilities equal
            <br />
            access to our services, including through an accessible
            <br />
            website. If you have questions, comments, or encounter
            <br />
            any difficulty in using our site, please contact:
            <br />
            <a
              className={classes.link}
              href="mailto: mckinsey_website_accessibility@mckinsey.com"
            >
              mckinsey_website_accessibility@mckinsey.com
            </a>
            <br />
            <br />
            &copy; McKinsey &amp; Company {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Centered };
