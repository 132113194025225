import React from "react";
import { createUseStyles } from "react-jss";

import DecisionLabLogo from "../images/decisionlab_logo.svg";
import OrgAdminLogo from "../images/orgadmin_logo.svg";
import OrgLabLogo from "../images/orglab_logo.png";
import TalentMatchLogo from "../images/talentmatch_logo.png";
import type { Key } from "../lib/identify-app";
import { identifyApp } from "../lib/identify-app";

const logos: Record<Key, string> = {
  "org-lab": OrgLabLogo,
  "org-admin": OrgAdminLogo,
  "decision-lab": DecisionLabLogo,
  "talent-match": TalentMatchLogo,
};

const useStyles = createUseStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
  },
  img: {
    width: "90%",
    maxHeight: "90px",
  },
});

const Logo: React.FunctionComponent = () => {
  const classes = useStyles();
  const { id, name } = identifyApp();
  return (
    <div className={classes.header}>
      <img src={logos[id]} className={classes.img} alt={name} />
    </div>
  );
};

export { Logo };
