import type { FunctionComponent } from "react";
import React, { useEffect, useReducer, useRef } from "react";

import { useQuery } from "react-query";

import { Button } from "../components/button";
import { Centered } from "../components/centered";
import { CustomInput } from "../components/input";
import { Loading } from "../components/loading";
import { Logo } from "../components/logo";
import { Message } from "../components/message";
import { getConfig } from "../lib/api";
import {
  clearDestinationUri,
  clearEmail,
  isEmailAddress,
  missingDestinationMessage,
  parseFragment,
  redirectToDestinationUri,
  setDestinationUri,
  setEmail,
} from "../lib/helpers";
import { buildAuthzUrl, getOidcDoc, tryExchangeToken } from "../lib/login";
import { setup } from "../lib/setup";
import { styles } from "../lib/styles";

interface State {
  code: string | null;
  orgAuthTokenExchange: boolean;
  mckIdOAuthSignIn: boolean;
  errorMessage: string | null;
}

interface Props {
  code: string;
  error: string;
  error_description: string;
}

const destinationUri = setDestinationUri();
let email: string | null = setEmail();

function formatError(error: string, error_description: string): string | null {
  if (error) {
    const rest = error_description ? ` - ${error_description}` : "";
    return `Login failed: ${error}${rest}`;
  }

  return null;
}

function reducer(
  state: State,
  action: { type: string; error?: unknown }
): State {
  switch (action.type) {
    case "TRY_EXCHANGE_TOKEN":
      return { ...state, mckIdOAuthSignIn: false, orgAuthTokenExchange: true };
    case "START_MCK_ID_OAUTH_SIGN_IN":
      return { ...state, mckIdOAuthSignIn: true, orgAuthTokenExchange: false };
    case "LOGIN_FAILED":
    case "INVALID_INPUT":
      return {
        code: null,
        mckIdOAuthSignIn: false,
        orgAuthTokenExchange: false,
        errorMessage: String(action.error),
      };
    case "CLEAR_ERROR":
      return { ...state, errorMessage: null, mckIdOAuthSignIn: false };
    default:
      throw new Error("Invalid type");
  }
}

function initState(props: Props): State {
  return {
    mckIdOAuthSignIn: false,
    orgAuthTokenExchange: false,
    code: props.code,
    errorMessage: formatError(props.error, props.error_description),
  };
}

export const OrgAuthSignIn: FunctionComponent<Props> = (props) => {
  const [state, dispatch] = useReducer(reducer, props, initState);

  const loginRef = useRef<HTMLInputElement>(null);

  useQuery("exchange-token", async () => {
    if (!state.code || state.mckIdOAuthSignIn) {
      // Can't start exchanging token if we don't have a McKinsey ID code
      // but this is not an error
      return;
    }

    if (!destinationUri) {
      dispatch({ type: "LOGIN_FAILED", error: "No destination URL found" });
      return;
    }

    dispatch({ type: "TRY_EXCHANGE_TOKEN" });

    try {
      const config = await getConfig();
      const token = await tryExchangeToken(config, state.code);

      clearDestinationUri();

      return redirectToDestinationUri(destinationUri, token);
    } catch (e: unknown) {
      dispatch({ type: "LOGIN_FAILED", error: e });
    }
  });

  useEffect(() => {
    if (email && loginRef?.current) {
      loginRef.current.value = email;

      email = null;
      clearEmail();

      void handleSubmit();
    }
  });

  const forgotPasswordQuery = useQuery("forgot-password", async () => {
    const config = await getConfig();

    return `${config.forgot_password_url}${config.client_id}`;
  });

  const handleSubmit = async (): Promise<void> => {
    dispatch({ type: "START_MCK_ID_OAUTH_SIGN_IN" });
    // Hopefully one day we can pass on the
    // login to the authentication provider
    const { login } = validateForm();

    if (null === login) {
      return;
    }

    try {
      const config = await getConfig();
      const doc = await getOidcDoc(config);

      if (!doc || !doc.authorization_endpoint) {
        throw new Error(
          "oidc well known doc missing or does not contain authorization endpoint"
        );
      }

      const url = await buildAuthzUrl(
        config,
        doc.authorization_endpoint,
        login
      );

      window.location.href = url;

      return;
    } catch (error: unknown) {
      dispatch({ type: "LOGIN_FAILED", error: String(error) });
    }
  };

  const validateForm = (): { login: string | null } => {
    const login = loginRef.current?.value?.trim() || "";

    if (!isEmailAddress(login)) {
      dispatch({
        type: "INVALID_INPUT",
        error: "Please enter a valid email address.",
      });

      return { login: null };
    }

    return { login: login };
  };

  const clearError = (): void => {
    dispatch({ type: "CLEAR_ERROR" });
  };

  if (!destinationUri) {
    return <Loading message={missingDestinationMessage} />;
  }

  if (state.orgAuthTokenExchange) {
    return <Loading />;
  } else {
    // Set sensible default for prod in case of failure
    const forgotPasswordUrl =
      forgotPasswordQuery.isLoading || forgotPasswordQuery.error
        ? "https://account.mckinsey.id/forgot-password"
        : forgotPasswordQuery.data;

    return (
      <Centered>
        <Logo />
        <Message />
        <form
          onSubmit={(event: React.FormEvent): void => {
            event.preventDefault();
            void handleSubmit();
          }}
        >
          <CustomInput
            inputRef={loginRef}
            onInput={clearError}
            name="login"
            placeholder="Email"
            disabled={state.mckIdOAuthSignIn}
          />
          {state.errorMessage && (
            <div style={styles.error}>{state.errorMessage}</div>
          )}
          <Button label="Continue" disabled={state.mckIdOAuthSignIn} />
        </form>
        <a style={styles.link} href={forgotPasswordUrl}>
          Forgot your password?
        </a>
      </Centered>
    );
  }
};

function go(): void {
  const fragments = parseFragment(window.location.hash.substring(1)) as Record<
    keyof Props,
    string
  >;
  window.location.hash = ""; // Remove the #
  setup(<OrgAuthSignIn {...fragments} />);
}

go();
