import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  "@keyframes rotate": {
    "0%": { transform: "translate(-50%, -50%) rotate(0deg)" },
    "50%": { transform: "translate(-50%, -50%) rotate(180deg)" },
    "100%": { transform: "translate(-50%, -50%) rotate(360deg)" },
  },
  wrapper: {
    position: "relative",
    display: "block",
    color: "#8dc63f",
    width: "100%",
    height: "96px",
  },
  outer: {
    animationName: "$rotate",
    margin: "auto",
    boxSizing: "border-box",
    display: "inline-block",
    float: "none",
    borderColor: "currentColor",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderStyle: "solid",
    borderWidth: "3px",
    top: "50%",
    left: "50%",
    borderRadius: "100%",
    position: "absolute",
    background: "transparent",
    animation: "x 2s cubic-bezier(.09, .57, .49, .9) infinite",
    width: "50px",
    height: "50px",
  },
});

const Spinner: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.outer} />
    </div>
  );
};

export { Spinner };
