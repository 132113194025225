import React from "react";
import type { FormEventHandler } from "react";
import { createUseStyles } from "react-jss";

import Triangle from "../images/rectangle.svg";

const useStyles = createUseStyles({
  input: {
    backgroundColor: "#fff",
    backgroundImage: `url(${Triangle})`,
    backgroundPosition: "109% -22px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "17px 17px",
    border: "1px solid #e6e8ed",
    boxSizing: "border-box",
    color: "#888",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "1.07143rem",
    height: "60px",
    letterSpacing: ".00429rem",
    lineHeight: "1.15",
    margin: "0 0 1rem",
    outline: "none",
    padding: "14px 13px",
    transition: "background-position .4s ease",
    width: "100%",
    "&:focus": {
      backgroundPosition: "calc(100% - 5px) 5px",
      border: "1px solid #888",
    },
    "&:disabled": {
      opacity: "0.6",
    },
    "&::placeholder": {
      color: "#aaa",
    },
  },
});

interface Props {
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  onInput: FormEventHandler | undefined;
}

export const CustomInput: React.FunctionComponent<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({ inputRef, onInput, ...props }) => {
  const classes = useStyles();
  return (
    <input
      className={classes.input}
      ref={inputRef ? inputRef : null}
      onInput={onInput}
      type="text"
      {...props}
    />
  );
};
