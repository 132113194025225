import React from "react";
import { createUseStyles } from "react-jss";

import type { Colors } from "../lib/identify-app";
import { identifyApp } from "../lib/identify-app";

const useStyles = createUseStyles({
  input: {
    backgroundColor: (colors: Colors) => {
      return colors.primary;
    },
    border: 0,
    borderRadius: "2px",
    color: "#fff",
    cursor: "pointer",
    fontSize: "20px",
    height: "60px",
    padding: "12px 46px",
    position: "relative",
    width: "100%",
    "&:disabled": {
      backgroundColor: (colors: Colors) => {
        return colors.disabled;
      },
      color: "#ccc",
      cursor: "default",
    },
    "&:disabled:hover": {
      backgroundColor: (colors: Colors) => {
        return colors.disabled;
      },
      boxShadow: "none",
      color: "#ccc",
      cursor: "default",
    },
    "&:hover": {
      backgroundColor: (colors: Colors) => {
        return colors.hover;
      },
      boxShadow:
        "0 1px 1px 0 rgba(0,0,0,.14), 0 1px 7px 0 rgba(0,0,0,.12), 0 1px 1px -1px rgba(0,0,0,.2)",
    },
  },
});

interface Props {
  label: string;
  disabled?: boolean;
}

const Button: React.FunctionComponent<Props> = ({
  label,
  disabled,
  ...props
}: Props) => {
  const { colors } = identifyApp();

  const classes = useStyles(colors);

  return (
    <input
      className={classes.input}
      disabled={disabled}
      type="submit"
      value={label}
      {...props}
    />
  );
};

export { Button };
