import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";

export function setup(children: React.ReactNode): void {
  document.addEventListener("DOMContentLoaded", () => {
    const container = document.body.appendChild(document.createElement("div"));

    const root = createRoot(container);

    const queryClient = new QueryClient();

    root.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </React.StrictMode>
    );
  });
}
