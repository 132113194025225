import type { AxiosError } from "axios";
import axios from "axios";

import type { ConfigurationDataAttributes } from "./api";

const VERIFIER_STORAGE_KEY = "verifier";

export interface OidcDoc {
  authorization_endpoint: string;
  token_endpoint: string;
}

function urlSafeString(s: string): string {
  return s.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");
}

/// The code verifier is a cryptographically random string using the characters A-Z, a-z, 0-9,
/// and the punctuation characters -._~ (hyphen, period, underscore, and tilde),
/// between 43 and 128 characters long.
function createCodeVerifier(): string {
  const a = new Uint8Array(32);
  crypto.getRandomValues(a);
  return urlSafeString(window.btoa(String.fromCharCode(...a)));
}

async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const base64Digest = window.btoa(
    String.fromCharCode(...new Uint8Array(digest))
  );

  return urlSafeString(base64Digest);
}

/**
 * Builds Authorization URL for the user to be redirected to
 * @param configuration Org-Auth config
 * @param authorizationEndpoint oidc Auth endpoint
 * @param login email, used as a hint to display on the next page
 * @returns URL with params
 */
export async function buildAuthzUrl(
  configuration: ConfigurationDataAttributes,
  authorizationEndpoint: string,
  login: string
): Promise<string> {
  if (!window.crypto || !window.isSecureContext) {
    throw new Error("crypto is not available");
  }

  const verifier = createCodeVerifier();

  sessionStorage.setItem(VERIFIER_STORAGE_KEY, verifier);

  const nonce = "n";

  const codeChallenge = await generateCodeChallenge(verifier);

  const url = new URL(authorizationEndpoint);

  url.searchParams.append("client_id", configuration.client_id);
  url.searchParams.append("redirect_uri", configuration.redirect_url);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("scope", "openid email profile offline_access");
  url.searchParams.append("state", ".");
  url.searchParams.append("response_mode", "fragment");
  url.searchParams.append("nonce", nonce);
  url.searchParams.append("code_challenge_method", "S256");
  url.searchParams.append("code_challenge", codeChallenge);
  url.searchParams.append("login_hint", login);

  return url.href;
}

/**
 * Gets the well known oidc configuration of the identity provider
 * @param configuration Org-Auth config
 * @returns OIDC doc of identify provider
 */
export async function getOidcDoc(
  configuration: ConfigurationDataAttributes
): Promise<OidcDoc> {
  try {
    const response = await axios.get<OidcDoc>(
      configuration.oidc_well_known_url
    );

    return response.data;
  } catch (e: unknown) {
    console.error(e);
    throw new Error("Failed to get oidc well known doc");
  }
}

/**
 * Exchanges a code for a JWT
 * @param configuration Org-Auth OAuth2 config
 * @param doc McKinsey ID oidc doc
 * @param code McKinsey ID code to exhange for token
 * @returns JSON
 */
async function exchangeCodeForToken(
  configuration: ConfigurationDataAttributes,
  tokenEndpoint: string,
  code: string
): Promise<{
  access_token: string;
}> {
  const codeVerifier = sessionStorage.getItem(VERIFIER_STORAGE_KEY);

  if (!codeVerifier) {
    throw new Error("missing code verifier in pkce flow");
  }

  const body = new URLSearchParams();
  body.append("client_id", configuration.client_id);
  body.append("grant_type", "authorization_code");
  body.append("redirect_uri", configuration.redirect_url);
  body.append("code", code);
  body.append("code_verifier", codeVerifier);

  try {
    const res = await axios.post<{ access_token: string }>(tokenEndpoint, body);

    return res.data;
  } catch (e: unknown) {
    console.error(e);

    throw new Error(`Could not exchange code for token`);
  }
}

async function exchangeMckIDJWTForOrgAuthJWT(
  configuration: ConfigurationDataAttributes,
  mckIDJWT: string
): Promise<string> {
  try {
    const res = await axios.post<{ data: { id: string } }>(
      configuration.sign_in_url,
      null,
      {
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: `Bearer ${mckIDJWT}`,
        },
      }
    );
    return res.data.data.id;
  } catch (e: unknown) {
    const err = e as AxiosError<{
      errors: [{ title: string; status: string; detail: string }];
    }>;

    const error = err.response?.data?.errors?.[0];

    if (error) {
      throw new Error(`${error.title} (${error.status}, ${error.detail})`);
    } else {
      console.error(e);
      throw new Error("Could not exchange McK ID JWT for Org-Auth JWT");
    }
  }
}

/**
 * Exchanges Mckinsey ID OAuth code for token and subsequently exchanges said token for OrgAuth token
 * @param configuration Org-Auth onfig
 * @param code McK ID OAuth code
 * @returns Org-Auth JWT
 */
export async function tryExchangeToken(
  configuration: ConfigurationDataAttributes,
  code: string
): Promise<string> {
  const doc = await getOidcDoc(configuration);

  if (!doc.token_endpoint) {
    throw new Error("oidc well known token endpoint missing");
  }

  // This signs in against McKinsey ID
  const response = await exchangeCodeForToken(
    configuration,
    doc.token_endpoint,
    code
  );

  return exchangeMckIDJWTForOrgAuthJWT(configuration, response.access_token);
}
