export const styles = {
  link: {
    color: "#2980b9",
    display: "inline-block",
    textDecoration: "none",
    marginTop: "10px",
  },
  header: {
    fontSize: "22px",
    marginBottom: "10px",
  },
  message: {
    color: "#F5A623",
    marginTop: "10px",
    fontWeight: "bold",
  },
  error: {
    color: "#e11313",
    marginBottom: "5px",
  },
};
