import React from "react";
import { createUseStyles } from "react-jss";

import { Centered } from "./centered";
import { Logo } from "./logo";
import { Spinner } from "./spinner";

const useStyles = createUseStyles({
  message: {
    color: "#888",
  },
});

interface Props {
  message?: string;
}

const Loading: React.FunctionComponent<Props> = ({ message }) => {
  const classes = useStyles();
  return (
    <Centered>
      <Logo />
      {!message && <Spinner />}
      {!!message && <div className={classes.message}>{message}</div>}
    </Centered>
  );
};

export { Loading };
