import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import * as xss from "xss";

import ExclamationCircle from "../images/exclamation-circle.svg";
import { getConfig, getMaintenanceMessage } from "../lib/api";

function sanitize(text: string): string {
  const options = {
    whiteList: {
      b: ["style"],
      em: ["style"],
      i: ["style"],
      u: ["style"],
      br: ["style"],
      p: ["style"],
    },
  };
  const filter = new xss.FilterXSS(options);
  return filter.process(text);
}

const useStyles = createUseStyles({
  wrapper: {
    display: "flex",
    marginTop: "-22px",
    marginBottom: "19px",
  },
  img: {
    width: "28px",
  },
  message: {
    textAlign: "left",
    color: "#003f5f",
    paddingLeft: "10px",
    paddingTop: "5px",
  },
});

const Message: React.FunctionComponent = () => {
  const classes = useStyles();
  const [message, setMessage] = useState<string | null>("");
  const messageIsPresent = !!message?.replace(/&nbsp;/g, "").trim();

  useEffect(() => {
    let mounted = true;

    void (async (): Promise<void> => {
      const config = await getConfig();

      const m = await getMaintenanceMessage(config.org_api_configurations_url);

      if (mounted && !!m) {
        setMessage(sanitize(m));
      }
    })();

    return () => {
      mounted = false;
    };
  }, [message, setMessage]);

  return (
    <>
      {messageIsPresent && (
        <div className={classes.wrapper}>
          <img
            className={classes.img}
            src={ExclamationCircle}
            alt="Maintenance"
          />
          <div
            className={classes.message}
            dangerouslySetInnerHTML={{ __html: message as string }}
          />
        </div>
      )}
    </>
  );
};

export { Message };
